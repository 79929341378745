import { booleanAttribute, Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[nxtDragAndDrop]',
  standalone: true,
})
export class DragAndDropDirective {
  @Input({ alias: 'nxtDragAndDrop', transform: booleanAttribute }) isActive: boolean = true;

  @HostBinding('class.drag-and-drop__file-over') private fileOver = false;

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    this.applyBehaviorOnDragEvents(event);
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent) {
    this.applyBehaviorOnDragEvents(event);
  }

  @HostListener('drop', ['$event']) public onDrop(event: DragEvent) {
    this.applyBehaviorOnDragEvents(event);
  }

  private applyBehaviorOnDragEvents(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.isActive) {
      this.fileOver = event.type === 'dragover';
    }
  }
}
